export const defaultGroupObj = { label: 'All Groups', value: 'all' };

export const defaultPlanArr = [{ label: 'All Plans', value: 'all' }];

export const defaultSelectedGroups = [
  {
    group: defaultGroupObj,
    plans: defaultPlanArr,
    locked: false
  }
];

export const groupChangeAlert =
  "Groups can't be changed once plans are assigned. Create a new group or delete the existing one if needed.";
