import {
  getPromise,
  postPromise,
  deletePromise,
  putPromise,
  patchPromise,
  postPromiseWithAttachment
} from './request';
import { API_ROUTES } from './constants';
import _ from 'lodash-es';
import he from 'he';
const Promise = require('bluebird');

export function getSubscriptionGroups (
  type = 'all',
  deletedPlans = 'false',
  deleted = 'false'
) {
  return getPromise(
    `/api/v1/management/subscription_groups.json?subscription_type=${type}&deleted=${deleted}&deleted_plans=${deletedPlans}`
  ).then((response) => response.body.subscription_groups);
}

export function updateOrder (order) {
  return putPromise(`/api/v1/management/subscription_groups/order.json`, {
    order: order
  }).then(
    (response) => response,
    (error) => Promise.reject(error.response.body)
  );
}

export function getSubscriptionGroupsLite (type = 'all', deleted = 'false') {
  return getPromise(
    `/api/v1/management/subscription_groups.json?subscription_type=${type}&lite=true&deleted=${deleted}`
  ).then((response) => response.body.subscription_groups);
}

export function getSubscriptionGroup (id) {
  return getPromise(`/api/v1/management/subscription_groups/${id}.json`).then(
    (response) => response.body.subscription_group
  );
}

export function destroySubscriptionGroup (id) {
  return deletePromise(`/api/v1/management/subscription_groups/${id}.json`);
}

export function createOrUpdateSubscriptionGroup (group) {
  const groupWithEncodedDescription = { ...group, description: encodeDescriptionToBase64(group.description), custom_attributes: encodeCustomAttributesToBase64(group.custom_attributes) };
  return postPromise('/api/v1/management/subscription_groups.json', {
    subscription_group: groupWithEncodedDescription
  }).then(
    (response) => response.body.subscription_group,
    (error) => Promise.reject(error.response.body)
  );
}

export function createSubsciptionsFromCSV (groupId, file) {
  return postPromiseWithAttachment(
    `/api/v1/management/subscription_groups/${groupId}/subscription_imports.json`,
    'subscriptions_csv',
    file
  ).then(
    (response) => response.body.message,
    (errors) => Promise.reject(errors.response.body.error)
  );
}

export function getSubscriberSubscriptions (provider, identity) {
  return getPromise(
    `/api/v1/management/subscribers/${provider}/${identity}/subscriptions.json`
  ).then((response) => response.body.subscriptions);
}

export function getSubscriberAssets (provider, identity) {
  return getPromise(
    `/api/v1/management/subscribers/${provider}/${identity}/assets.json`
  ).then((response) => response.body.assets);
}

export function cancelSubscriberSubscription (
  provider,
  identity,
  subscriptionId
) {
  return putPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriptions/${subscriptionId}/cancel.json`
  ).then(
    (response) => response.body,
    (errors) => {
      return Promise.reject(errors.response.body.error);
    }
  );
}

export function createRefund (provider, identity, subscriptionId, amount) {
  return postPromise(
    `/api/v1/management/subscribers/${provider}/${identity}/subscriptions/${subscriptionId}/refunds.json`,
    {
      refund: {
        amount: amount === '' ? undefined : parseInt(amount)
      }
    }
  ).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body.error)
  );
}

export function getRefunds (provider, identity, subscriptionId) {
  return getPromise(
    `/api/v1/management/subscribers/${provider}/${identity}/subscriptions/${subscriptionId}/refunds.json`
  ).then((response) => response.body.refunds);
}

export function postCreateSubscription (
  provider,
  identity,
  subscriptionPlanId,
  payment,
  subscription,
  attemptToken,
  notes = ''
) {
  return postPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriptions.json`,
    {
      subscription: Object.assign(
        {
          subscription_plan_id: subscriptionPlanId,
          payment: payment,
          notes: notes
        },
        subscription
      ),
      attempt_token: attemptToken
    }
  ).then(
    (response) => response.body.subscription,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function postPreviewSubscription (provider, identity, subscription) {
  return postPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriptions/preview.json`,
    subscription
  ).then(
    (response) => {
      return response.body;
    },
    (error) => {
      return Promise.reject(error.response.body.error);
    }
  );
}

export function getSubscriberIdentities (provider, identity) {
  return getPromise(
    `/api/v1/management/subscribers/${provider}/${identity}/subscriber_identities.json`
  ).then((response) => response.body.subscriber_identities);
}

export function createIdentity (provider, identity, newProvider, value) {
  return postPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriber_identities.json`,
    { subscriber_identity: { provider: newProvider, value: value } }
  ).then(
    (response) => response.body.subscriber_identity,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getSubscriptions (
  subscriptionGroupId,
  pageNum = 1,
  type = 'standard'
) {
  return getPromise(
    `/api/v1/management/subscription_groups/${subscriptionGroupId}/subscriptions.json?page=${pageNum}&subscription_type=${type}`
  ).then((response) => response.body);
}

export function subscriptionSearchApi (filter, term, pageNum) {
  const statusValue = filter.status.join('&status[]=');
  const plansValue = filter.plans.join('&subscription_plan_id[]=');
  const params = {
    page: pageNum,
    term,
    subscription_group_id: filter.group,
    'status[]': statusValue,
    'subscription_plan_id[]': plansValue,
    cancelled: filter.cancelled
  };
  const paramsWithDate = filter['sub-date'].start
    ? {
      ...params,
      start_date: filter['sub-date'].start,
      end_date: filter['sub-date'].end
    }
    : params;

  return getPromise(API_ROUTES.SUBSCRIPTION_SEARCH, paramsWithDate).then(
    (response) => response.body
  );
}

export function getAnalyticsSubscriptions (params, pageNum = 1) {
  const dateString = params['sub-date'].start
    ? `&start_date=${params['sub-date'].start}&end_date=${params['sub-date'].end}`
    : '';
  return getPromise(
    `${API_ROUTES.SUBSCRIPTION_GROUPS}/${
      params.group
    }/subscriptions.json?page=${pageNum}&subscription_plan_id[]=${params.plans.join(
      '&subscription_plan_id[]='
    )}&status[]=${params.status.join('&status[]=')}&cancelled=${
      params.cancelled
    }${dateString}`
  ).then((response) => response.body);
}

export function getSubscriptionAttempts (
  subscriptionGroupId,
  pageNum = 1,
  type = 'standard'
) {
  return getPromise(
    `/api/v1/management/subscription_groups/${subscriptionGroupId}/subscription_attempts.json?page=${pageNum}&subscription_type=${type}`
  ).then((response) => response.body);
}

export function getSubscriberSubscriptionAttempts (
  provider,
  identity,
  pageNum = 1
) {
  return getPromise(
    `/api/v1/management/subscribers/${provider}/${identity}/subscription_attempts.json?page=${pageNum}`
  ).then((response) => response.body);
}

export function searchSubscribers (subscriptionGroupId, provider, query) {
  const encodedQuery = encodeURIComponent(query);

  return getPromise(
    `/api/v1/management/subscriber_identities?provider=${provider}&identity=${encodedQuery}`
  ).then((response) => response.body.subscriber_identities);
}

export function getPaymentGateways () {
  return getPromise('/api/v1/management/payment_gateways.json').then(
    (response) => response.body
  );
}

export function validatePaymentGatewayCredentials (paymentGateway) {
  return getPromise(
    `/api/v1/management/payment_gateways/validate_payment_gateway_credentials.json?payment_gateway=${paymentGateway}`
  ).then((response) => response.body);
}

export function enablePaymentGateway (paymentGateway, enable) {
  return putPromise('/api/v1/management/payment_gateways/enable_gateway.json', {
    payment_gateway: paymentGateway,
    enable: enable
  }).then((response) => response.body);
}

export function updatePaymentGatewayCredentials (paymentGateways) {
  return putPromise('/api/v1/management/payment_gateways.json', {
    payment_gateways: paymentGateways
  }).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body.errors)
  );
}

export function patchSubscriptionForSubscriber (
  provider,
  identity,
  id,
  patchObject
) {
  return patchPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriptions/${id}`,
    patchObject
  ).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body)
  );
}

export function patchExtendSubscription (
  provider,
  identity,
  id,
  patchObject
) {
  return patchPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriptions/${id}/extend`,
    patchObject
  ).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body)
  );
}

export function postCreateRemotePlan (type, subscriptionPlanId) {
  return postPromise(
    `/api/v1/management/subscription_plans/${subscriptionPlanId}/payment_gateways.json`,
    { payment_gateway: { type: type } }
  ).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body)
  );
}

export function postCreateVoucherifyProduct (subscriptionPlanId) {
  return postPromise(
    `/api/v1/management/subscription_plans/${subscriptionPlanId}/voucherify.json`
  ).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body)
  );
}

export function connectRecurringPlan (subscriptionPlanId, paymentProvider) {
  return postPromise(
    `/api/v1/management/subscription_plans/${subscriptionPlanId}/${paymentProvider}/connect.json`
  ).then(
    (response) => response.body,
    (errors) => {
      return Promise.reject(errors.response.body);
    }
  );
}

export function updateRecurringPlan (
  subscriptionPlanId,
  paymentProvider,
  remotePlanId = ''
) {
  return putPromise(
    `/api/v1/management/subscription_plans/${subscriptionPlanId}/${paymentProvider}/update.json`,
    { remote_plan_id: remotePlanId }
  ).then(
    (response) => response.body,
    (errors) => {
      return Promise.reject(errors.response.body);
    }
  );
}

export function updateVoucherifyProduct (subscriptionPlanId) {
  return putPromise(
    `/api/v1/management/subscription_plans/${subscriptionPlanId}/voucherify.json`
  ).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body)
  );
}

export function getSubscriptionPlan (subscriptionGroupId, subscriptionPlanId) {
  return getPromise(
    `/api/v1/management/subscription_groups/${subscriptionGroupId}/subscription_plans/${subscriptionPlanId}.json`
  ).then(
    (response) => response.body.subscription_plan,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getAccountSettings () {
  return getPromise('/api/v1/management/accounts/settings.json').then(
    (response) => response.body
  );
}

const encodeDescriptionToBase64 = (description) => {
  if (typeof description === 'string') {
    return window.btoa(he.encode(description));
  }
  return null;
};

const encodeCustomAttributesToBase64 = (customAttributes) => {
  const encodedCustomAttributes = customAttributes.map(
    (attribute) => {
      const attr = { ...attribute };
      attr.value = window.btoa(window.encodeURIComponent(attr.value));
      return attr;
    });
  return encodedCustomAttributes || [];
};

const switchPlanConfig = (switchPlanConfig) => {
  if (switchPlanConfig) {
    return _.omit(switchPlanConfig, 'selected_plan_details');
  }
  return null;
};

export function createOrUpdateSubscriptionPlan (subscriptionGroupId, plan) {
  const planWithEncodedDescriptionAndSwitchConfig = { ...plan,
    description: encodeDescriptionToBase64(plan.description),
    custom_attributes: encodeCustomAttributesToBase64(plan.custom_attributes),
    upgrade_plan_config: switchPlanConfig(plan.upgrade_plan_config),
    crossgrade_plan_config: switchPlanConfig(plan.crossgrade_plan_config),
    downgrade_plan_config: switchPlanConfig(plan.downgrade_plan_config) };
  return postPromise(
    `/api/v1/management/subscription_groups/${subscriptionGroupId}/subscription_plans.json`,
    { subscription_plan: planWithEncodedDescriptionAndSwitchConfig }
  ).then(
    (response) => response.body.subscription_plan,
    (error) => Promise.reject(error.response.body)
  );
}

export function destroySubscriptionPlan (
  subscriptionGroupId,
  subscriptionPlanId
) {
  return deletePromise(
    `/api/v1/management/subscription_groups/${subscriptionGroupId}/subscription_plans/${subscriptionPlanId}.json`
  ).then(
    (response) => {
      return response.body;
    },
    (error) => Promise.reject(error.response.body)
  );
}

export function postRenewSubscription (provider, identity, subscriptionPlanId) {
  return postPromise(
    `/api/v1/subscribers/${provider}/${identity}/subscriptions/${subscriptionPlanId}/renewals.json`,
    { subscription: { payment: { payment_type: 'manual' } } }
  ).then(
    (response) => response.body.subscription,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getAssetCategoriesCors (dynamicOnly = false) {
  return getPromise(
    `/api/v1/asset_categories.json?dynamic_only=${dynamicOnly}&key=${_.get(
      window.subConfig,
      ['currentAccountKey'],
      ''
    )}`
  ).then((response) => response.body.asset_categories);
}

export function getSearchCustomAttributes (searchUrl) {
  return getPromise(
    `/api/v1/asset_categories/search_custom_category_values.json?search_url=${searchUrl}`
  ).then((response) => response.body.custom_attribute_values);
}

export function getMetadata (assetCategoryId) {
  return getPromise(
    `/api/v1/management/asset_categories/${assetCategoryId}/metadata`
  ).then((response) => response.body.metadata);
}

export function postCouponCode (coupon) {
  return postPromise('/api/v1/management/vouchers.json', {
    voucher: coupon
  }).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getCoupons (pageNum = 1) {
  return getPromise(`/api/v1/management/vouchers.json?page=${pageNum}`).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function deleteCoupon (id) {
  return deletePromise(`/api/v1/management/vouchers/${id}.json`).then(
    (response) => {
      return response.body;
    },
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getAccountSubscriptionsReports (pageNum = 1) {
  return getPromise(
    `/api/v1/management/reports/subscriptions.json?page=${pageNum}`
  ).then((response) => response.body);
}

export function getInvoiceReports (pageNum = 1) {
  return getPromise(
    `/api/v1/management/reports/invoices.json?page=${pageNum}`
  ).then((response) => response.body.invoices_reports);
}

export function getAccountAllReports (pageNum = 1) {
  return getPromise(`/api/v1/management/reports/all.json?page=${pageNum}`).then(
    (response) => response.body
  );
}

export function getSubscriptionAttemptsReports (id) {
  return getPromise(
    `/api/v1/management/subscription_groups/${id}/reports/subscription_attempts.json`
  ).then((response) => response.body.subscription_attempts_reports);
}

export function getSubscriptionImportsReports (id) {
  return getPromise(
    `/api/v1/management/subscription_groups/${id}/reports/subscription_imports.json`
  ).then((response) => response.body.subscription_imports_reports);
}

export function getSubscriptionAttemptsCsv (id) {
  return getPromise(
    `/api/v1/management/subscription_groups/${id}/subscription_attempts.csv`
  ).then((response) => response.body.message);
}

export function getNotificationSettings () {
  return getPromise('/api/v1/management/notification_settings.json').then(
    (response) => response.body.notification_settings,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function updateNotificationSettings (settings) {
  return putPromise('/api/v1/management/notification_settings.json', {
    notification_settings: settings
  }).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body.error)
  );
}

export function getQuintypeSettings () {
  return getPromise('/api/v1/management/quintype_settings.json').then(
    (response) => response.body.account,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function updateQuintypeSettings (settings) {
  return putPromise('/api/v1/management/quintype_settings.json', {
    account: settings
  }).then(
    (response) => response.body,
    (errors) => Promise.reject(errors.response.body.errors)
  );
}

export function postCreateUpdateAccountUser (accountUser) {
  return postPromise('/api/v1/management/account_users.json', {
    account_user: accountUser
  }).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body)
  );
}

export function getAccountUsers () {
  return getPromise(`/api/v1/management/account_users.json`).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function deleteAccountUser (id) {
  return deletePromise(`/api/v1/management/account_users/${id}.json`).then(
    (response) => {
      return response.body;
    },
    (error) => Promise.reject(error.response.body.error)
  );
}

export function searchUser (query) {
  const encodedQuery = encodeURIComponent(query);
  return getPromise(
    `/api/v1/management/users/search.json?email=${encodedQuery}`
  ).then((response) => response.body);
}

export function getCurrentAccountUserDetails () {
  return getPromise(
    `/api/v1/management/account_users/${window.subConfig['currentAccountUserId']}.json`
  ).then((response) => response.body.account_user);
}

export function switchAccount (switchToAccountId) {
  return getPromise(`/accounts/${switchToAccountId}/switch.json`);
}

export function getDownloadUrl (filename) {
  return getPromise(
    `/api/v1/management/reports/${filename}/download_url.json`
  ).then((response) => response.body.download_url);
}

export function getAnalyticalReportSubscriptionCreated (
  fromDate = '',
  toDate = '',
  granularity = 'DAY',
  groupBy
) {
  return getPromise(
    `/api/v1/management/analytical_reports/created_subscriptions.json?from_date=${fromDate}&to_date=${toDate}&granularity=${granularity}&group_by=${groupBy}`
  ).then((response) => response.body.data);
}

export function getAnalyticalReportRevenue (
  fromDate = '',
  toDate = '',
  granularity = 'DAY',
  currency,
  groupBy
) {
  return getPromise(
    `/api/v1/management/analytical_reports/revenue.json?from_date=${fromDate}&to_date=${toDate}&granularity=${granularity}&currency=${currency}&group_by=${groupBy}`
  ).then((response) => response.body.data);
}

export function getSubscriptionsDueToRenew (params) {
  return getPromise(
    '/api/v1/management/analytical_reports/upcoming_renewals',
    params
  ).then((response) => response.body.data);
}

export function getAnalyticalReportsData (dataApiPath, params) {
  return getPromise(
    `/api/v1/management/analytical_reports/${dataApiPath}`,
    params
  ).then((response) => response.body);
}

export function getMeteringSettings () {
  return getPromise('/api/v1/management/metering_settings.json').then(
    (response) => response.body.metering_settings
  );
}

export function getNewMeteringSettings () {
  return getPromise('/api/v1/management/metering_settings/new.json').then(
    (response) => response.body.metering_settings
  );
}

export function createOrUpdateMeteringSetting (meteringSettings) {
  if (meteringSettings.id) {
    return putPromise(
      `/api/v1/management/metering_settings/${meteringSettings.id}.json`,
      { metering_settings: meteringSettings }
    ).then(
      (response) => response.body.metering_setting,
      (error) => {
        return Promise.reject(error.response.body);
      }
    );
  } else {
    return postPromise(`/api/v1/management/metering_settings.json`, {
      metering_settings: meteringSettings
    }).then(
      (response) => response.body.metering_setting,
      (error) => Promise.reject(error.response.body)
    );
  }
}

export function createOrUpdateTaxRate (taxRate = {}) {
  return postPromise(`/api/v1/management/tax_rates.json`, {
    tax_rate: taxRate
  }).then(
    (response) => response.body.tax_rate,
    (error) => Promise.reject(error.response.body)
  );
}

export function getTaxRates () {
  return getPromise(`/api/v1/management/tax_rates.json`).then(
    (response) => response.body.tax_rates
  );
}

export function destroyTaxRate (id) {
  return deletePromise(`/api/v1/management/tax_rates/${id}.json`);
}

export function getInvoiceSettings () {
  return getPromise(`/api/v1/management/invoice_settings.json`).then(
    (response) => response.body.invoice_settings
  );
}

export function createOrUpdateInvoiceSettings (invoiceSettings = {}) {
  const template = invoiceSettings.template || '';

  // Convert string to UTF-8 bytes, then Base64 encode
  const encodedTemplate = window.btoa(
    new TextEncoder().encode(template)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );

  return postPromise(`/api/v1/management/invoice_settings.json`, {
    invoice_settings: Object.assign({}, invoiceSettings, {
      template: encodedTemplate
    })
  }).then(
    (response) => response.body.invoice_settings,
    (error) => Promise.reject(error.response.body)
  );
}

export function getWebhooks () {
  return getPromise(`/api/v1/management/webhooks.json`).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getWebhook (webhookId) {
  return getPromise(`/api/v1/management/webhooks/${webhookId}`).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function createWebhook (webhook) {
  return postPromise(`/api/v1/management/webhooks`, { webhook: webhook }).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function deleteWebhook (webhookId) {
  return deletePromise(`/api/v1/management/webhooks/${webhookId}`).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getEvents () {
  return getPromise(`/api/v1/management/events`).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getAccounts () {
  return getPromise(`/api/v1/admin/accounts`).then(
    (response) => response.body.accounts,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function createOrUpdateAccount (account) {
  return postPromise(`/api/v1/admin/accounts`, { account: account }).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function getUniqueCurrencies (fromDate, toDate) {
  return getPromise(
    `/api/v1/management/analytical_reports/unique_currencies?from_date=${fromDate}&to_date=${toDate}`
  ).then((response) => response.body.data);
}

export function getAcccountConfig () {
  return getPromise(`/api/v1/management/accounts`).then(
    (response) => response.body
  );
}

export function updateAcccountConfig (data) {
  return putPromise('api/v1/management/accounts', data).then(
    (response) => response.body
  );
}

export function getOrganizations () {
  return getPromise('/api/v1/management/organizations').then(
    (response) => response.body
  );
}

export function getOrganization (id) {
  return getPromise(`/api/v1/management/organizations/${id}`).then(
    (response) => response.body
  );
}

export function createOrganization (data) {
  return postPromise('/api/v1/management/organizations', data).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function updateOrganization (id, data) {
  return putPromise(`/api/v1/management/organizations/${id}`, data).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function deleteOrganization (id) {
  return deletePromise(`/api/v1/management/organizations/${id}`);
}

export function getSubscriptionById (id) {
  return getPromise(
    `${API_ROUTES.SUBSCRIPTIONS}/${id}`
  ).then((response) => response.body);
}

export function getSubscriptionMembers (
  primarySubscriber,
  subscriptionId,
  page = 1
) {
  return getPromise(
    `/api/v1/subscribers/${primarySubscriber['provider']}/${primarySubscriber['identity']}/subscriptions/${subscriptionId}/members?page=${page}&include_deleted=true`
  ).then((response) => response.body);
}

export function addMemberToSubscription (
  primarySubscriber,
  subscriptionId,
  member
) {
  return postPromise(
    `/api/v1/subscribers/${primarySubscriber['provider']}/${primarySubscriber['identity']}/subscriptions/${subscriptionId}/members`,
    { member }
  ).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export function deleteMembersFromSubscription (
  primarySubscriber,
  subscriptionId,
  ids
) {
  return deletePromise(
    `/api/v1/subscribers/${primarySubscriber['provider']}/${
      primarySubscriber['identity']
    }/subscriptions/${subscriptionId}/members?ids[]=${ids.join('&ids[]=')}`
  ).then((response) => response.body);
}

export function addSubscriptionMembersFromCSV (subscriptionId, file) {
  return postPromiseWithAttachment(
    `/api/v1/management/subscriptions/${subscriptionId}/members`,
    'members_csv',
    file
  ).then(
    (response) => response.body.message,
    (errors) => Promise.reject(errors.response.body.error)
  );
}

export function getSubscriptionMemberImportReports (subscriptionId) {
  return getPromise(
    `/api/v1/management/reports/subscriptions/${subscriptionId}/subscription_member_imports.json`
  ).then((response) => response.body.member_imports_reports);
}

export function getInvoices (groupId, planId, page, dates) {
  const dateString = dates.start
    ? `&start_date=${dates.start}&end_date=${dates.end}`
    : '';
  return getPromise(
    `${API_ROUTES.INVOICES}?subscription_group_id=${groupId}&subscription_plan_id[]=${planId}&page=${page}${dateString}`
  ).then((response) => response.body);
}

export function searchInvoices (groupId, planId, page, searchTerm, dates) {
  const dateString = dates.start
    ? `&start_date=${dates.start}&end_date=${dates.end}`
    : '';
  return getPromise(
    `${API_ROUTES.INVOICES}/search?subscription_group_id=${groupId}&subscription_plan_id[]=${planId}&page=${page}&term=${searchTerm}${dateString}`
  ).then((response) => response.body);
}

export function downloadInvoicesCsv (
  subscriptionGroupId,
  subscriptionPlanId,
  dates
) {
  const dateString = dates.start
    ? `&start_date=${dates.start}&end_date=${dates.end}`
    : '';
  return getPromise(
    `${API_ROUTES.INVOICES}/download?subscription_group_id=${subscriptionGroupId}&subscription_plan_id[]=${subscriptionPlanId}${dateString}`
  ).then((response) => response.body.message);
}

export function downloadSubscriptionsCsv (params) {
  const dateString = params['sub-date'].start
    ? `&start_date=${params['sub-date'].start}&end_date=${params['sub-date'].end}`
    : '';
  return getPromise(
    `${API_ROUTES.SUBSCRIPTIONS}/download?subscription_group_id=${
      params.group
    }&subscription_plan_id[]=${params.plans.join(
      '&subscription_plan_id[]='
    )}&status[]=${params.status.join('&status[]=')}&cancelled=${
      params.cancelled
    }${dateString}`
  ).then((response) => response.body.message);
}

export function downloadSubscriptionsToRenewCsv (params) {
  return getPromise(
    '/api/v1/management/analytical_reports/upcoming_renewals/download',
    params
  ).then((response) => response.body.message);
}

export function createAccount (account) {
  return postPromise('/api/v1/accounts.json', { account: account }).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.errors)
  );
}

export function getSubscribers (page) {
  return getPromise(API_ROUTES.SUBSCRIBERS, { page }).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.errors)
  );
}

export function loginUser (loginParams) {
  return postPromise('login', loginParams).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.message)
  );
}

export function signupUser (signupParams) {
  return postPromise('signup', signupParams).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.message)
  );
}

export function forgotPassword (forgotParams) {
  return postPromise('forgot', forgotParams).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body)
  );
}

export function resetPassword (resetParams) {
  return postPromise('/reset', resetParams).then(
    (response) => response.body,
    (error) => Promise.reject(error.response.body.error)
  );
}

export const generatePresignedParams = (fileName) =>
  getPromise(`/api/v1/management/presigned_params/${fileName}`).then(
    (response) => response.body,
    (error) => Promise.reject(error)
  );
