import React from 'react';
import './select-and-search.scss';
import Search from '../Icons/Search';
import { searchSubscribers } from '../../../api';
import SelectField from '../../library-components/SelectField/SelectField';
import { identityValidate } from '../../../helpers/utils';

class SelectAndSearch extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      provider: 'email',
      identity: '',
      createNewSubscriber: ''
    };
    this.goToSubscriber = this.goToSubscriber.bind(this);
    this.getSubscribers = this.getSubscribers.bind(this);
  }

  getSubscribers (input, callback) {
    if (!input) {
      callback(null, { options: [] });
    }

    searchSubscribers(this.props.subscriptionGroupId, this.state.provider, input).then(identities =>
      callback(null, { options: identities.map(identity => ({ label: identity, value: identity })) }),
    err => callback(err));

    const validatedInput = identityValidate(this.state.provider, input);

    this.setState({ createNewSubscriber: validatedInput ? '/subscribers/' + this.state.provider + '/' + input + '.html' : null });
  }

  goToSubscriber (value) {
    this.setState({ identity: value });
    const url = '/subscribers/' + this.state.provider + '/' + value.value + '.html';
    return value ? window.open(url, '_self') : null;
  }

  render () {
    const IDENTITY_PROVIDER = ['quintype', 'wordpress', 'email', 'phone_number', 'supertokens'].map((k) => ({ label: k, value: k }));
    const SearchPlaceholder = <span className='select-identity__placeholder'><Search width='16' height='16' />Search</span>;
    const noResultsText = <span className='select-identity__no-result-text'> No Results Found {(this.state.identity !== '' || this.state.createNewSubscriber) && <a href={this.state.createNewSubscriber}> (Add New User)</a>}</span>;

    return (
      <form method='GET'>
        <div className='select-and-search'>
          <SelectField options={IDENTITY_PROVIDER}
            value={this.state.provider}
            onChange={(e) => this.setState({ provider: e.value })}
            clearable={false}
            className='select-provider'
            placeholder='Select'
            searchable={false}
            valueKey='value' />

          <SelectField placeholder={SearchPlaceholder}
            loadOptions={this.getSubscribers}
            value={this.state.identity}
            onChange={this.goToSubscriber}
            clearable={false}
            async
            noResultsText={noResultsText}
            className='select-identity'
            displayArrow={false}
            cache={false}
            valueKey='value'
          />
        </div>
      </form>
    );
  }
}

export default SelectAndSearch;
