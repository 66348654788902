import React, { useEffect, useState } from 'react';
import { get } from 'lodash-es';

import { Dialog } from '@quintype/em/components/dialog';
import { CreatableSelect } from '@quintype/em/components/select';

import { Link } from '../../library-components';
import { getSubscriptionGroupsLite } from '../../../api';
import Trash from '../../../pages/Icons/trash';
import {
  defaultGroupObj,
  defaultPlanArr,
  defaultSelectedGroups,
  groupChangeAlert
} from './constant';

import './plans-filter.scss';

const SubscriptionDropdowns = ({ updateCouponGroupsAndPlans, coupon }) => {
  const [data, setData] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState(defaultSelectedGroups);
  const [groupChange, isGroupChange] = useState(false);
  const [count, setCount] = useState(0);

  const groupsAndPlans = get(coupon, 'groups_and_plans', []);

  const updateCouponFilter = (updatedGroups) => {
    const filteredGroups = updatedGroups.filter(
      (group) => group.group !== null
    );

    const coupongroups = {
      groups_and_plans: filteredGroups.map((item) => ({
        group_id: item.group.value,
        plans: item.plans.map((plan) =>
          plan.value === 'all' ? 'all' : Number(plan.value)
        )
      }))
    };

    const updatedCoupon = Object.assign({}, coupon, coupongroups);

    updateCouponGroupsAndPlans(updatedCoupon);
  };

  useEffect(() => {
    const fetchSubscriptionGroups = async () => {
      try {
        const groups = await getSubscriptionGroupsLite('all', true);
        setData(groups);
      } catch (error) {
        console.error('Error fetching subscription groups:', error);
      }
    };

    fetchSubscriptionGroups();
  }, []);

  useEffect(() => {
    if (
      groupsAndPlans.length > 0 &&
      data.length > 0 &&
      groupsAndPlans[0].group_id !== 'all' &&
      count === 0
    ) {
      setSelectedGroups((prevSelectedGroups) => {
        const updatedGroups = [...prevSelectedGroups];

        groupsAndPlans.forEach((item, index) => {
          const selectedgrp = data.find((group) => group.id === +item.group_id);
          if (!selectedgrp) return;

          const groupValue = { label: selectedgrp.name, value: item.group_id };
          const planIds = get(item, 'plans', []);

          const plansArr =
            planIds.length > 0 && planIds[0] !== 'all'
              ? planIds.map((planId) => {
                const planDetails = selectedgrp.subscription_plans.find(
                  (subscriptionPlan) => subscriptionPlan.id === planId
                );
                return {
                  label: planDetails.title,
                  value: '' + planId
                };
              })
              : defaultPlanArr;

          updatedGroups[index] = {
            group: groupValue,
            plans: plansArr,
            locked: true
          };
        });

        setCount(1);

        return updatedGroups;
      });
    }
  }, [data, groupsAndPlans, count]);

  const handleGroupChange = (index, field, value) => {
    const updatedGroups = [...selectedGroups];

    if (updatedGroups[index].locked) {
      isGroupChange(true);
      return;
    }

    updatedGroups[index] = {
      group: value,
      plans: defaultPlanArr,
      locked: false
    };

    updateCouponFilter(updatedGroups);
    setSelectedGroups(updatedGroups);
  };

  const handlePlanChange = (index, value) => {
    let plans = [...value];
    if (
      plans.some((plan) => plan.value === 'all') &&
      (value[value.length - 1].value === 'all' || value.length === 0)
    ) {
      plans = defaultPlanArr;
    } else {
      plans = plans.filter((plan) => plan.value !== 'all');
    }

    const updatedGroups = [...selectedGroups];
    if (plans.length < 1 || allGroupsSelected) {
      updatedGroups[index] = { ...updatedGroups[index], plans, locked: false };
    } else {
      updatedGroups[index] = { ...updatedGroups[index], plans, locked: true };
    }
    updateCouponFilter(updatedGroups);
    setSelectedGroups(updatedGroups);
  };

  if (data.length < 1) {
    return null;
  }

  const availableGroups = data.map((group) => ({
    label: group.name,
    value: group.id.toString()
  }));

  const allGroupsSelected =
    selectedGroups.length === 1 &&
    selectedGroups[0].group &&
    selectedGroups[0].group.label === 'All Groups';

  const dummyRow = selectedGroups.find((group) => group.group === null);

  const addMore = () => {
    if (!allGroupsSelected && !dummyRow) {
      setSelectedGroups([
        ...selectedGroups,
        { group: null, plans: [], locked: false }
      ]);
    }
  };

  const removeRow = (index) => {
    let updatedGroups = selectedGroups.filter((_, i) => i !== index);
    if (updatedGroups.length === 0) {
      updatedGroups = defaultSelectedGroups;
    }
    updateCouponFilter(updatedGroups);
    setSelectedGroups(updatedGroups);
  };

  return (
    <div className="plans-selector-wrapper">
      <h3 className="eligible-plans-title">Eligible Plans</h3>
      <p className="eligible-plans-ot-message">
        Currently supports one-time plans only
      </p>
      <div
        className={`${
          allGroupsSelected || !!dummyRow
            ? 'add-more-groups-plans-button-non-active-wrapper'
            : ''
        } add-more-wrapper`}
      >
        <Link iconLeft="add-new" onClick={addMore}>
          Add More
        </Link>
      </div>
      {selectedGroups.map((selection, index) => {
        const filteredGroups =
          index === 0
            ? [defaultGroupObj, ...availableGroups]
            : availableGroups.filter(
              (group) =>
                !selectedGroups.some(
                  (s, i) =>
                    i !== index && s.group && s.group.value === group.value
                )
            );
        const selectedGroupData = data.find(
          (group) =>
            group.id.toString() ===
            (selection.group && selection.group.value
              ? selection.group.value
              : '')
        );
        const otPlans = selectedGroupData
          ? selectedGroupData.subscription_plans.filter(
            (plan) => plan.recurring === false
          )
          : [];
        const availablePlans = otPlans.map((plan) => ({
          label: plan.title,
          value: plan.id.toString()
        }));
        const filteredPlans = selection.group
          ? [{ label: 'All Plans', value: 'all' }, ...availablePlans]
          : [];

        return (
          <div key={index} className="plans-filter-container">
            <div className="group-select">
              <CreatableSelect
                label="Group"
                options={filteredGroups}
                value={selection.group}
                onChange={(option) => handleGroupChange(index, 'group', option)}
                isClearable
                isSearchable
                menuPlacement="top"
              />
            </div>
            <div className="plan-select">
              <CreatableSelect
                label="Plans"
                options={filteredPlans}
                value={selection.plans}
                onChange={(value) => handlePlanChange(index, value)}
                isMulti
                isClearable={false}
                isDisabled={!selection.group}
                menuPlacement="top"
                closeMenuOnSelect={false}
              />
            </div>
            <button onClick={() => removeRow(index)} className="delete-button">
              <Trash />
            </button>
          </div>
        );
      })}
      <Dialog
        isOpen={groupChange}
        onClose={() => isGroupChange(false)}
        classname="plans-filter-dialogue"
      >
        <p className="dialog-text">{groupChangeAlert}</p>
      </Dialog>
    </div>
  );
};

export default SubscriptionDropdowns;
