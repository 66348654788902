import React from 'react';
import { get } from 'lodash-es';
import validate from 'validate.js';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import parseISO from 'date-fns/parseISO';
import set from 'date-fns/set';
import { postCouponCode } from '../../api';

import { NumberField } from '@quintype/em/components/number-field';
import { Inspector } from '@quintype/em/components/inspector';
import { Dialog } from '@quintype/em/components/dialog';
import Select from '@quintype/em/components/select';
import { TextField } from '@quintype/em/components/text-field';
import { Checkbox } from '@quintype/em/components/checkbox';
import { ErrorMessage } from '@quintype/em/components/error-message';
import { DateRangePicker } from '@quintype/em/components/date-range-picker';
import PlansFilter from './plans-filter';

import './coupon-inspector.scss';

const DISCOUNT_TYPE = [
  { label: 'Percent', value: 'percent' },
  { label: 'Amount', value: 'amount' }
];

function customValidation ({ startTimeStamp }) {
  const todayTimeStamp = getUnixTime(
    // startTimeStamp from date picker is date instance with time as (12:00 am)
    // so we are formatting the current date with 12:00 am time
    set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0
    })
  );

  if (startTimeStamp < todayTimeStamp) {
    return {
      message: 'Past date is not allowed',
      type: 'from'
    };
  }
}

class CouponInspector extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      coupon: this.props.coupon,
      couponValidityEnabled: !!get(this.props.coupon, 'start_timestamp', false),
      errors: [],
      isInspectorActive: this.props.isInspectorActive,
      isDatePopupOpen: false,
      isActionButtonDisabled: this.props.isActionButtonDisabled,
      isSecondaryButtonDisabled: !this.props.isActionButtonDisabled,
      isDialogOpen: false,
      dialogMode: 'save'
    };

    this.postCoupon = this.postCoupon.bind(this);
    this.updateCouponGroupsAndPlans =
      this.updateCouponGroupsAndPlans.bind(this);
  }

  // make sure to bind the afterStateUpdateCallck to this(context)
  updateCouponField (couponObject, afterStateUpdateCallback) {
    let newCoupon = {};
    if (Object.prototype.hasOwnProperty.call(couponObject, 'discount_type')) {
      newCoupon = Object.assign({}, this.state.coupon, couponObject, {
        value: ''
      });
    } else {
      newCoupon = Object.assign({}, this.state.coupon, couponObject);
    }

    this.setState(
      {
        coupon: newCoupon,
        isActionButtonDisabled: false,
        isSecondaryButtonDisabled: false
      },
      () => {
        if (afterStateUpdateCallback) {
          afterStateUpdateCallback();
        }
      }
    );
  }

  updateCouponGroupsAndPlans (updatedCoupon) {
    this.setState({
      coupon: updatedCoupon,
      isActionButtonDisabled: false
    });
  }

  getCouponConstraints (type) {
    if (type === 'amount') {
      return {
        presence: true,
        numericality: {
          strict: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 2147483647,
          message: 'must be less than or equal to 21474836'
        }
      };
    } else {
      return {
        presence: true,
        numericality: {
          strict: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 100
        }
      };
    }
  }

  dialogContent (type) {
    if (type === 'deactivate') {
      return {
        textContent: 'Do you want to deactivate this coupon?',
        buttonLabel: 'Deactivate',
        actionButtonCallBack: () => {
          this.updateCouponAndPost(false);
        }
      };
    }

    if (type === 'save') {
      return {
        textContent: 'Do you want to save changes to this coupon?',
        buttonLabel: 'Save',
        actionButtonCallBack: () => {
          this.updateCouponAndPost(true);
        }
      };
    }
  }

  usageLimitConditions () {
    const context = this;
    const { coupon } = this.state;
    const isUsageLimitSelected = !!get(coupon, 'enabled_usage_limit', false);
    const isPerUserUsageLimitSelected = !!get(
      coupon,
      'enabled_user_usage_limit',
      false
    );

    const usageLimitchecks = {
      usage_limit: {
        presence: true,
        numericality: {
          strict: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 20000
        }
      }
    };

    const perUserUsageLimitChecks = {
      user_usage_limit: {
        presence: true,
        numericality: {
          strict: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo:
            Number(context.state.coupon['usage_limit']) || 20000
        }
      }
    };

    return Object.assign(
      {},
      isUsageLimitSelected ? usageLimitchecks : {},
      isPerUserUsageLimitSelected ? perUserUsageLimitChecks : {}
    );
  }

  timeStampConditions () {
    const { couponValidityEnabled } = this.state;

    return couponValidityEnabled
      ? {
        start_timestamp: {
          presence: true
        },
        end_timestamp: {
          presence: true
        }
      }
      : {};
  }

  constraints () {
    return Object.assign(
      {},
      {
        coupon_code: {
          presence: true,
          length: {
            maximum: 50,
            minimum: 1,
            tooShort: "can't be blank",
            tooLong: 'is too long (maximum is %{count} characters)'
          },
          format: {
            pattern: '(?=.*[a-z])[a-z0-9]+',
            flags: 'i',
            message: 'must be alphanumeric. No special characters.'
          }
        },
        display_name: {
          presence: false,
          length: {
            maximum: 100,
            tooLong: 'is too long (maximum is %{count} characters)'
          }
        },
        discount_type: { presence: true },
        discount_value: this.getCouponConstraints(
          get(this.state.coupon, 'discount_type')
        )
      },
      this.usageLimitConditions(),
      this.timeStampConditions()
    );
  }

  createCoupon (type) {
    if (this.state.coupon.active === undefined) {
      this.setState({
        coupon: Object.assign({}, this.state.coupon, { active: false })
      });
    }

    const couponCodeForConstraints = Object.assign(
      {},
      {
        coupon_code: this.state.coupon.code,
        display_name: this.state.coupon.title,
        discount_type: this.state.coupon.discount_type,
        discount_value: this.state.coupon.value,
        usage_limit: this.state.coupon['usage_limit'],
        user_usage_limit: this.state.coupon['user_usage_limit'],
        start_timestamp: this.state.coupon.start_timestamp,
        end_timestamp: this.state.coupon.end_timestamp
      }
    );

    const validatedData = validate(
      couponCodeForConstraints,
      this.constraints()
    );

    if (validatedData) {
      this.setState({ errors: validatedData });
    } else {
      this.setState({
        isDialogOpen: true,
        dialogMode: type,
        errors: []
      });
    }
  }

  // updates local state and then posts the data to server
  updateCouponAndPost (isCouponActive) {
    this.updateCouponField({ active: isCouponActive }, this.postCoupon);
  }

  postCoupon () {
    postCouponCode(this.state.coupon).then(
      () => {
        this.setState({ errors: [], isActionButtonDisabled: true });
        this.props.actionInspector();
      },
      (errors) => {
        errors['coupon_code'] = errors['code'];
        errors['display_name'] = errors['title'];
        errors['discount_value'] = errors['value'];
        this.setState({ errors: errors, isDialogOpen: false });
      }
    );
  }

  discountValue () {
    const value = get(this.state.coupon, 'value', '');
    return get(this.state.coupon, 'discount_type') === 'amount' && value
      ? value / 100
      : value;
  }

  discountValueOnChange (e) {
    return get(this.state.coupon, 'discount_type') === 'amount'
      ? this.updateCouponField({ value: e * 100 })
      : this.updateCouponField({ value: e });
  }

  render () {
    const {
      coupon,
      errors,
      isActionButtonDisabled,
      isSecondaryButtonDisabled,
      dialogMode,
      isDialogOpen,
      couponValidityEnabled,
      isDatePopupOpen
    } = this.state;

    const {
      enabled_usage_limit: usageLimitEnabled,
      enabled_user_usage_limit: perUserUsageEnabled
    } = coupon;

    const dialogContent = this.dialogContent(dialogMode);

    const startTimeStamp = getUnixTime(parseISO(coupon.start_timestamp));
    const endTimeStamp = getUnixTime(parseISO(coupon.end_timestamp));

    const dateRangePickerProps =
      startTimeStamp && endTimeStamp
        ? {
          startTimeStamp,
          endTimeStamp
        }
        : {};

    const isTimestampNotSelected = get(errors, ['start_timestamp', 0], false);

    return (
      <div className="coupons-edit-wrapper">
        <Inspector
          title={'Coupon Details'}
          isActive={this.props.isInspectorActive}
          onClose={() => this.props.onClose()}
          onActionButtonClick={() => this.createCoupon('save')}
          secondaryButtonLabel="Deactivate"
          onSecondaryButtonClick={() => {
            this.createCoupon('deactivate');
          }}
          isSecondaryButtonDisabled={isSecondaryButtonDisabled}
          actionButtonLabel={'Save'}
          isActionButtonDisabled={isActionButtonDisabled}
        >
          <TextField
            label="Coupon Code"
            placeholder="Type coupon code here"
            value={coupon.code || ''}
            onChange={(e) => this.updateCouponField({ code: e })}
            errorMessage={get(errors, ['coupon_code', '0'], '')}
          />
          <TextField
            label="Display Name"
            placeholder="Type Display name here"
            value={coupon.title || ''}
            onChange={(e) => this.updateCouponField({ title: e })}
            errorMessage={get(errors, ['display_name', '0'], '')}
          />
          <Select
            label="Discount Type"
            value={get(coupon, 'discount_type')}
            onChange={(e) =>
              this.updateCouponField({ discount_type: e ? e.value : '' })
            }
            errorMessage={get(errors, ['discount_type', '0'], '')}
            isClearable={true}
            options={DISCOUNT_TYPE}
          />
          <NumberField
            label="Discount Value"
            placeholder="Type Discount Value here"
            value={this.discountValue()}
            onChange={(e) => this.discountValueOnChange(e)}
            errorMessage={get(errors, ['discount_value', '0'], '')}
          />
          <div className="checkbox-container">
            <Checkbox
              id="coupon-validity"
              label="Apply validity"
              onChange={(checked) => {
                this.setState({
                  couponValidityEnabled: checked,
                  isActionButtonDisabled: false,
                  isSecondaryButtonDisabled: false
                });
                if (!checked) {
                  this.updateCouponField({
                    start_timestamp: null,
                    end_timestamp: null
                  });
                }
              }}
              checked={couponValidityEnabled}
            />
          </div>
          <div className="at-date-range-picker-container">
            {couponValidityEnabled && (
              <>
                <DateRangePicker
                  {...dateRangePickerProps}
                  dateFieldClass={isTimestampNotSelected ? 'red-border' : ''}
                  onDateRangeSelect={(startStamp, endStamp) => {
                    this.updateCouponField({
                      start_timestamp: fromUnixTime(startStamp).toISOString(),
                      end_timestamp: fromUnixTime(endStamp).toISOString()
                    });
                  }}
                  customValidation={customValidation}
                  onDatePopupOpenOrClose={(popupState) => {
                    if (popupState) {
                      this.setState({
                        isDatePopupOpen: true
                      });
                    } else {
                      this.setState({
                        isDatePopupOpen: false
                      });
                    }
                  }}
                />
                <div className="error-message-container">
                  <ErrorMessage
                    message={
                      isTimestampNotSelected ? 'Please select date range' : ''
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="checkbox-container">
            <Checkbox
              id="per-usage"
              label="Apply usage limit"
              onChange={(checked) => {
                const couponState = checked
                  ? { enabled_usage_limit: checked }
                  : { enabled_usage_limit: checked, usage_limit: null };
                this.updateCouponField(couponState);
              }}
              checked={get(coupon, 'enabled_usage_limit', false)}
            />
          </div>
          {usageLimitEnabled && (
            <NumberField
              label={'Usage Limit'}
              value={get(coupon, 'usage_limit', '') || ''}
              onChange={(value) => {
                this.updateCouponField({ usage_limit: value });
              }}
              placeholder="Type usage limit here"
              errorMessage={get(errors, ['usage_limit', '0'], '')}
            />
          )}
          <div className="checkbox-container">
            <Checkbox
              id="per-user-usage"
              label="Apply per user usage limit"
              onChange={(checked) => {
                const couponState = checked
                  ? { enabled_user_usage_limit: checked }
                  : {
                    enabled_user_usage_limit: checked,
                    user_usage_limit: null
                  };
                this.updateCouponField(couponState);
              }}
              checked={get(coupon, 'enabled_user_usage_limit', false)}
            />
          </div>
          {perUserUsageEnabled && (
            <NumberField
              label={'Per User Usage Limit'}
              value={get(coupon, 'user_usage_limit', '') || ''}
              onChange={(value) => {
                this.updateCouponField({ user_usage_limit: value });
              }}
              placeholder="Type per user usage limit here"
              errorMessage={get(errors, ['user_usage_limit', '0'], '')}
            />
          )}
          {/* This increases the height of parent container when date popup is open
           * as normal flow parent (not absolute/ fixed positioned) does not account for
           * height of absolutely/fixed positioned element (date popup). Although a fixed positioned
           * parent accounts height  for fixed positioned child, still we need some space below */}
          {isDatePopupOpen && <div className="coupon-parent-ht-increase"></div>}

          <PlansFilter
            updateCouponGroupsAndPlans={this.updateCouponGroupsAndPlans}
            coupon={this.state.coupon}
          />
        </Inspector>
        <Dialog
          isOpen={isDialogOpen}
          actionButtonLabel={dialogContent.buttonLabel}
          onAction={dialogContent.actionButtonCallBack}
          onClose={(_) => {
            this.setState({
              isDialogOpen: false
            });
          }}
        >
          <p className="dialog-text">{dialogContent.textContent}</p>
        </Dialog>
      </div>
    );
  }
}

export { CouponInspector };
